.loader-container{
    height:1000px;
    width:100%;
}

.submit-loader {
	z-index: 1200;
	position:fixed;
}

.loader-container > .loader-wrapper {
    position:fixed;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
}

.loader-wrapper > div {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #686c6d;
    margin-top: 20px;
    text-align:center;
}

.loader-wrapper > .loader {
    margin-left:auto;
    margin-right:auto;
}

.btn-sign, .btn-sign:focus {
    width: 112px;
    height: 33px;
    border-radius: 16px;
    border: none;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
    background-color: #f9a54a;
    position: fixed;
    bottom: 36px;
    right: 46px;
    font-weight: bold;
    letter-spacing: normal;
    font-family: 'Open Sans';
    color: #ffffff;
    outline: none;
}

.btn-sign:hover{
    opacity:0.7;
}

.invoice-list-header {
    font-family: 'Open Sans';
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #686c6d;
    margin-left:30px;
    margin-top:24px;
    margin-bottom:10px;
}

.select-invoice {
    width: 25px;
    height: 25px;
    border: solid 0.5px #e8ebec;
    background-color: #fafafb;
}

.invoice-box > div {
    flex:1;
}

.invoice-list {
    list-style: none;
    width:100%;
    padding:0;
}

.invoice-list > li {
    margin: 16px 0 16px 0;
    border: solid 0.5px #e8ebec;
    border-left: solid 3px #f8ac5b;
}

.row {
    display: flex;
}

.sign-modal-header {
    display:flex;
    height:54px;
    margin:0;
}

.modal-close, .modal-close:focus {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width:40px;
    height:40px;
}

.modal-apply{
    display:none;
}

.sign-header {
    flex: 1;
    text-align: center;
    line-height: 54px;
    height: 22px;
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.2px;
    text-align: center;
    color: #686c6d;
    margin: 0;
}

.review-modal-header {
    background-color:#ffffff;
    width:100%;
    margin:0;
    height:54px;
}

.review-modal-title {
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 54px;
    letter-spacing: -0.18px;
    text-align: center;
    color: #686c6d;
}

.ReactModal__Body--open {
    overflow-y: hidden;
}

.review-modal-btn-apply {
    width: 104px;
    height: 30.9px;
    border-radius: 13.5px;
    border: solid 2px #019ddd;
    background-color: #019ddd;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 30.9px;
    letter-spacing: normal;
    color: #ffffff;
    position: absolute;
    right: 30px;
    bottom: 25px;
}

/* Customize the label (the container) */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    border-radius:1px;
    cursor: pointer;
    font-size: 22px;
    height:30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    /* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 7px;
    height: 17px;
    width: 17px;
    border-radius:2px;
    background-color: inherit;
    border: 1px solid #ddd;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: #f8ac5b;
    border:none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.react-responsive-modal-overlay {
    margin:auto;
    padding: 0 !important;
    background:transparent !important;
}

.close-icon {
    width:30px;
    height:30px;
    margin-left:8px;
    margin-top:12px;
}

.check-icon {
    width:30px;
    height:30px;
    margin-right:8px;
    margin-top:12px;
}

.rotate-instructions {
    display:none;
}

.no-invoices-cotainer {
    height: 2000px;
}

.no-invoices {
    border: 0.5px solid #e8ebec;
    margin: 0px 29px;
    height: 50px;
}

    .no-invoices > p {
        line-height: 50px;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #686c6d;
    }

.signature-confirm-text {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    color: #818181;
    margin:0 64px 17px 64px !important;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
    .invoice-container {
        margin: 0px 20px 0px 20px;
    }

    .no-invoices {
        border: 0.5px solid #e8ebec;
        margin: 0px 10px;
        height: 50px;
    }

    .invoice-box {
        display: flex;
        background-color: #fafafb;
        padding: 13px 0px 0px 0px;
    }

    .checkmark {
        left: 8px;
    }

    .rotate-instructions {
        display: flex;
        width: 100%;
        height: 100%;
        align-items:center;
        justify-content:center
    }
    
    .sign-content{
        display:none;
    }
}

.react-responsive-modal-modal {
    max-width: unset !important;
    width: 60%;
    height: 60%;
    padding: 0 !important;
    margin: auto;
}

@media screen and (max-height: 770px) and (orientation: landscape) {
    .react-responsive-modal-modal {
        max-width: unset !important;
        width: 85%;
        height: 82%;
        padding: 0 !important;
        margin: auto;
    }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
    .react-responsive-modal-overlay {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
    }

    .react-responsive-modal-modal {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
    }

    .sign-modal-header {
        background-color: #686C6D !important;
        width: 100%;
        margin: 0 0 15px 0;
        height: 54px;
        color: #fff;
    }

    .sign-modal-header > .sign-modal-header-separator {
        display: none;
    }

    .sign-header {
        color: #fff;
        line-height: 54px;
    }

    .modal-apply, .modal-apply:focus {
        display:block;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        width: 40px;
        height: 40px;
    }

}

@media screen and (min-width: 481px) {
    .invoice-container {
        margin: 8px 30px 8px 30px;
        padding: 16px;
        background-color: #ffffff;
    }

    .invoice-box {
        display: flex;
        background-color: #fafafb;
        padding: 13px 40px 11px 13px;
    }

    .checkmark {
        left: 0;
    }
}