.timeentry-list {
    list-style:none;
    padding:0;
}

.timeentry-header-desktop > p {
    flex: 1;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight:600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #686c6d;
    margin: 0 0 0 16px;
}

.timeentry-header-mobile > p {
    flex: 1;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight:600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #686c6d;
    margin: 11px 0 5px 16px;
}

.timeentry-header-mobile > p:first-child {
    flex:1;
    margin-left: 10px;
}


.timeentry-header-mobile > p:nth-child(2) {
    flex: 3.3;
}
    
.timeentry-header-mobile > p:nth-child(3){
    flex:1.5;
}

.timeentry-header-mobile > p:nth-child(4) {
    flex: 1.5;
    margin-right: 10px;
}

@media screen and (max-width: 480px) {
    .timeentry-header-desktop {
        display: none;
    }

    .timeentry-header-mobile {
        display: flex;
        background: #fff;
    }

    .timeentry-list > li {
        margin: 0px;
        padding: 0 0 0 0;
    }

        .timeentry-list > li:nth-child(odd) {
            background-color: #f9f9f9;
        }

        .timeentry-list > li:nth-child(even) {
            background-color: #fff;
        }
}

@media screen and (min-width: 481px) {
    .timeentry-header-desktop {
        display: flex;
    }

    .timeentry-header-mobile {
        display: none;
    }

    .timeentry-list > li {
        margin: 12px 0px 12px 0px;
        padding: 0 0 0 0;
        background:#fff;
    }
}
