.invoice-header {
    display: flex;
}

.invoice-header > div {
    flex: 1;
}

.invoice-header-mobile > div {
    flex: 1;
}

.invoice-header > div > p {
    margin:0 0 0 16px;
}

.mobile-header-field {
    margin: 0;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #686c6d;
}

.project {
    margin: 0;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #686c6d;
}

.invoice-header-key {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #cccccc;
}

    

    .invoice-header-value {
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #686c6d;
    }

.icon-button {
    background: none;
    color: #f8ac5b;
    border: none;
    padding: 0;
    top:0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    min-height:unset;
    height:35px;
}

.icon-button:focus {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.toggle-icon{
    width:30px;
    height:30px;
    margin-right:8px;
    margin-left:8px;
}

.hr-no-style {
    margin: 0;
}

.header-separator{
    margin-left:16px;
}

@media screen and (max-width: 480px) {
    .invoice-header-desktop {
        display: none;
    }

    .invoice-header-mobile {
        display: flex;
        padding-bottom: 12px;
    }

    .mobile-timeentry-list {
        margin-left: -35px;
    }

    .invoice-header-key:first-of-type {
        font-size: 14px;
        font-weight: 600;
    }
}

@media screen and (min-width: 481px) {
    .invoice-header-desktop{
        display:block;
    }

    .invoice-header-mobile {
        display: none;
    }
}
