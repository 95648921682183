.btn-apply {
    width: 112px;
    height: 33px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: bold;
    line-height:33px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    border-radius: 16.5px;
    border:none;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.15);
    background-color: #f9a54a;
    color: #fff;
    padding: 0;
    margin: 0;
    margin-right:42px;
}

.name, .name:focus {
    width: 256px;
    height: 33px;
    border: solid 0.5px #e5e6e7;
    outline:none;
    position: relative;
    display: block;
    margin: 0px 0 0 64px;
    font-size:12px;
    padding:14px 15px 14px 15px;
}

.signature-footer{
    display:flex;
    width:100%;
    justify-content:space-between;
    margin-top:31px;
}

.error-text {
    color: #f86a5b;
    margin:0;
}

.m-signature-pad--body {
    border: solid 1px #cccccc;
    margin-left: 64px;
    margin-right: 64px;
}

.m-signature-pad--footer {
    margin-left: 64px;
}

.m-signature-pad--body > canvas {
    width: 100%;
    height: 175px;
}

.clear-btn-desktop, .clear-btn-desktop:focus {
    border: 0;
    padding: 0;
    color: #f8ac5b;
    background: inherit;
    margin-left: 64px;
    margin-top: 12px;
    outline: none;
}

.clear-btn-mobile, .clear-btn-mobile:focus {
    border: 0;
    padding: 0;
    color: #f8ac5b;
    background: inherit;
    margin-left: 64px;
    margin-top: 0px;
    outline: none;
}

.clear-btn-desktop:hover, .clear-btn-mobile:hover {
    border: 0;
    padding: 0;
    color: #f8ac5b;
    opacity: 0.6;
}

.clear-btn-mobile{
    display:none;
}

@media screen and (max-height: 480px) and (orientation: landscape){
    .m-signature-pad--body > canvas {
        width: 100%;
        height: 150px;
    }

	.sigCanvas {
		width: 85%;
		height: 150px;
	}

    .clear-btn-mobile {
        float: right;
        margin-right: 64px;
        display:block;
    }

    .clear-btn-desktop{
        display:none;
    }

    .btn-apply{
        display:none;
    }

    .signature-footer {
        margin-top: 8px;
    }
}

.sigCanvas {
	width: 85%;
	height: 175px;
	border: solid 1px #cccccc;
	margin-left: 64px;
	margin-right: 64px;
}
