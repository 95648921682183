.timeentry-card {
    border-radius: 1px;
    background-color: inherit;
    border: solid 0.5px #e8ebec;
}

.timeentry-row-desktop {
    display: flex;
    height: 40px;
}

.timeentry-row-mobile {
    display: flex;
    height: 40px;
}

.timeentry-row-desktop > p {
    flex: 1;
    margin: 0 0 0 16px;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    color: #686c6d;
}

.timeentry-row-mobile > p {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: normal;
    color: #686c6d;
}

.timeentry-row-mobile > p:first-child {
    margin: 0 0 0 10px;
}
    
.timeentry-row-mobile > p:nth-child(1) {
    flex: 1;
}

.timeentry-row-mobile > p:nth-child(2) {
    flex: 3.3;
}

.timeentry-row-mobile > p:nth-child(3) {
    flex: 1.5;
}

.timeentry-row-mobile > p:nth-child(4) {
    flex: 1.5;
}

@media screen and (max-width: 480px) {
    .timeentry-row-desktop {
    display: none;
}

    .timeentry-row-mobile {
        display: flex;
    }
}

@media screen and (min-width: 480px) {
    .timeentry-row-desktop {
        display: flex;
    }

    .timeentry-row-mobile {
        display: none;
    }
}